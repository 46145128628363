sv.sideMenu = function () {
  $('.openMenu').click(function () {
    $('.openMenu').toggleClass('hide-open')
    $('.closeMenu').toggleClass('show-close')
    $('.mobile-menu').css('left', '0px')
    $('body').addClass('mobile-menu-opened')
  })

  $('.closeMenu, #overlay').click(function () {
    $('.openMenu').toggleClass('hide-open')
    $('.closeMenu').toggleClass('show-close')
    $('.mobile-menu').css('left', '-250px')
    $('body').removeClass('mobile-menu-opened')
  })
}
