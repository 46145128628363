(function ($) {
  $.fn.coverImage = function (options) {
    const settings = $.extend({
    }, options)

    let element
    return this.each((index, item) => {
      element = $(item)
      element.parent().css('background-image', 'url(' + $(item).attr('src') + ')')
      element.parent().css('background-size', 'cover')
      if (element.data('cover-image-position')) {
        element.parent().css('background-position', element.data('cover-image-position'))
      }
      element.hide()
    })
  }
}(jQuery))
