window.sv.PartnersSlick = function () {
  // if ($(window).width() > 375) {
  if ($('.sv07-partners').length) {
    $('.sv07-partners .slider').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: false,
      nextArrow: false,
      variableWidth: true
    })
  }
  // }
}
