(function ($) {
  function setup (element) {
    if (!!element.data('collapser-button') && !!element.data('collapser-target')) {
      let button = element.find(element.data('collapser-button'))
      let target = element.find(element.data('collapser-target'))
            // config button event
      button.on('click', () => {
        target.slideToggle()
        let state = button.attr('data-collapser-target-opened') === 'true' ? 'false' : 'true'
        button.attr('data-collapser-target-opened', state)
        target.attr('data-collapser-target-opened', state)
      })
            // setup target
      target.hide()
    }
  }

  $.fn.collapser = function (options) {
    const settings = $.extend({
    }, options)

    let element
    return this.each((index, item) => {
      setup($(item))
    })
  }
}(jQuery))
