import cf7 from '../functions/_contact-form-7'

jQuery('#sv05-load-more').click(function (e) {
  var $clickedElement = $(this)
  var $currentPage = $(this).attr('data-page')
  var $authorId = $(this).attr('data-tag')
  var $gavirtualpath = $(this).attr('data-ga-virtual-path')
  var $gavirtualtitle = $(this).attr('data-ga-virtual-title')
  var extra = $(this).attr('data-extra') ? JSON.parse($(this).attr('data-extra')) : {}
  var data = {
    action: 'sv05_load_more_handler',
    nonce: sv05_load_more_handler.nonce,
    currentPage: $currentPage,
    tagId: $authorId
  }
  for (var v in extra) {
    data[v] = extra[v]
  }

    // Execute the ajax request
  $.post(sv05_load_more_handler.url, data, function (response) {
    if (response.success) {
      if (!!$gavirtualtitle && !!$gavirtualpath && $gavirtualpath !== '' && response.data.data.posts.length > 0) {
        var page = $gavirtualpath + '/load-' + $currentPage + '/',
          title = $gavirtualtitle + ' - Página: ' + $currentPage
                // console.log(page, title)

        dataLayer.push({
          'event': 'virtualPageview',
          'virtualPageURL': page,
          'virtualPageTitle': title
        })
      }

      $clickedElement.attr('data-page', response.data.data.current_page)
      if (!response.data.data.show_more) {
        $clickedElement.hide()
      }
      $('.sv05-js-articles-container').append(response.data.output)
      $clickedElement.trigger('data-updated')
      window.sv.MobileSocialShare()
      window.sv.SocialSharing()
      if (window.GSLoader) {
        window.GSLoader.reset()
      }
      cf7.initAll()
    }
  })
})
