sv.newsletterResponse = function () {
  var message = $('.lp-newsletter__wrapper .message')
  var shareSuccess = $('.lp-newsletter__success')
  var subscribeCopy = $('.lp-newsletter-content h2')

  /*
  document.addEventListener('wpcf7mailsent', function (event) {
    message.addClass('sent')
    shareSuccess.addClass('sent')
    subscribeCopy.addClass('sent')

    $('.form-container').hide()
    $('.wpcf7-response-output').html('Obrigada por subscrever').show()
  });
  */

	/* Validation Events for changing response CSS classes */
	// Apresenta ecrã de resultado da nova newsletter
  jQuery('.js-newsletter-form').each(function () {
    document.addEventListener('wpcf7mailsent', function (event) {
      var id = jQuery(this).find('.wpcf7').attr('id')

      if (event.detail.id === id) {
        jQuery('.js-newsletter-form').addClass('hidden')
        jQuery('.js-newsletter-form-success').removeClass('hidden')
      }
    }, false)
  })

	// Listeners
  $('.js-modal-newsletter-open').on('click', function () {
    $('.js-modal-newsletter').fadeIn('fast')
  })
  $('.js-modal-newsletter-close').on('click', function () {
    $('.js-modal-newsletter').fadeOut('fast')
  })
}
