/**
 * Set the related div fixed to the bottom if exists.
 * @constructor
 */
window.sv.RelatedToBottom = function () {
  $(window).on('resize', () => {
    var el = $('.sidebar')

    if (el.length) {
      el.height(el.parent().find('.post-container').outerHeight())
    }
  }).trigger('resize')
}
