import vars from './public/_vars'

import numbercounter from './public/plugins/_number_counter'

// ajax requests
import sv03 from './public/ajaxrequests/_sv03_category_load_more'
import sv04 from './public/ajaxrequests/_sv04_tag_load_more'
import svdiabetes from './public/ajaxrequests/_sv_diabetes_load_more'
import svcardio from './public/ajaxrequests/_sv_cardio_load_more'
import sv05 from './public/ajaxrequests/_sv05_author_load_more'
import sv06 from './public/ajaxrequests/_sv06_general_load_more'
import sv08 from './public/ajaxrequests/_sv08_latest_load_more'
import sv_quartz from './public/ajaxrequests/_sv_quartz'
import sv_recipes_group from './public/ajaxrequests/_sv_recipesgroup_load_more'

// functions
import header from './public/functions/_header'
import partners from './public/functions/_partners_slick'
import search from './public/functions/_search'
import sidemenu from './public/functions/_sideMenu'
import classifypwithimage from './public/functions/_classify_p_with_img'
import newsletterResponse from './public/functions/_newsletterResponse'
import ajaxViewController from './public/functions/_ajax-view-controller'
import openMagAjaxViewController from './public/functions/_open-mag-ajax-view-controller'
import ellipsis from './public/functions/_ellipsis'
import openMagMap from './public/functions/_openMagMap'
import openMagScroll from './public/functions/_openMagScroll'
import openMagLocationNav from './public/functions/_openMagLocationNav'
import openMagNumberCounters from './public/functions/_openMagNumberCounters'
import avoidHashLinksToJumpTop from './public/functions/_avoidHashLinksToJumpTop'
import coverImages from './public/functions/_cover-images'
import collapsers from './public/functions/_collapsers'
import cookiesConsent from './public/functions/_cookies-consent'
import moreMenu from './public/functions/_moreMenu'
import checkbox_styled_parent_label_fn from './public/functions/_checkbox-styled-parent-label.js'
import mortgageSimulator from './public/functions/_mortgageSimulator'

// plugins
import comments from './public/plugins/_comments'
import footer from './public/plugins/_footer'
import isonscreen from './public/plugins/_is_on_screen'
import progress from './public/plugins/_progress'
import relatedtobottom from './public/plugins/_related_to_bottom'
import socialsharing from './public/plugins/_socialSharing'
import coverimage from './public/plugins/_cover-image'
import collapser from './public/plugins/_collapser'
import checkbox_styled_parent_label from './public/plugins/_checkbox-styled-parent-label.js'

import adminBar from './public/ajaxrequests/_admin_bar'

import start from './public/_init'

// services
import gallery from './public/services/gallery/gallery-slider'
