/**
 * Control the header
 */
window.sv.headerHandler = function () {
  // if ($(window).width() > 375) {
  $(window).on('scroll', function () {
    var scrollPos = $(window).scrollTop()
            /*
            if (scrollPos <= 0) {
                if( $(".navbar-brand").is(':visible') ){
                    $(".navbar-brand").css("display","none");
                    $(".search-sub-menu").css("display","none");
                    $(".main-header").toggleClass("under-shadow");
                    $(".pre-header").show();
                }
            } else {
                if( ! $(".navbar-brand").is(':visible') ){
                    $(".navbar-brand").css("display","inline-block");
                    $(".search-sub-menu").css("display","inline-block");
                    $(".main-header").toggleClass("under-shadow");
                    $(".pre-header").hide();
                }
            }
            */
  })
  // }
}

window.sv.MenuItemHandler = function () {
    /*
    var $currentMenuItemID;

    $(".menu-item").mouseover(
        function() {
            $("progress").hide();
            $currentMenuItemID = $(this).attr('id');
            $(".menu-item-content[data-menuid='"+$currentMenuItemID+"']").show();
        }
    );

    $(".menu-item").mouseleave( function(){
        $("progress").show();
        $(".menu-item-content[data-menuid='"+$currentMenuItemID+"']").hide();
    });

    $(".menu-item-content").mouseleave( function(){
        $("progress").show();
        jQuery(this).hide();
    });

    $(".menu-item-content").mouseover( function(){
        $("progress").hide();
        jQuery(this).show();
    });
    */

    // Mobile menu container
  $('#mobile-menu-controller').click(function () {
    $('.mobile-menu-header').toggleClass('active')
    $(this).toggleClass('active')
  })
}
