import ajax from './../../common/ajax'
/**
 * Control the header
 */

window.sv.setupCookieConsent = function () {
  $('body').addClass('cookies-message')

  $(window).on('resize', function () {
    $('body.cookies-message').css('padding-bottom', $('.cookies').outerHeight())
  }).trigger('resize')

  $('.cookies .cta-sv-wrapper').on('click', function () {
    ajax('/wp-json/v1/cookies_consent', (data) => {
      $('body').addClass('cookies-message--hidden')
    })
  })
}

setTimeout(function () {
  window.sv.setupCookieConsent()
}, 1000)
