window.sv.HandleProgressBar = function () {
  var lastArticles = $('.last-articles')
  var editorsPick = $('.editors-pick')

  var lastArticlesHeight = lastArticles.length > 0 ? lastArticles.outerHeight() : 0
  var editorsPickHeight = editorsPick.length > 0 ? editorsPick.outerHeight() : 0
  var winHeight = $(window).height(),
    docHeight = $(document).height() - lastArticlesHeight - editorsPickHeight - $('footer').outerHeight(),
    progressBar = $('progress'),
    max, value

    /* Set the max scrollable area */
  max = docHeight - winHeight
  progressBar.attr('max', max)

  value = $(window).scrollTop()
  progressBar.attr('value', value)

  $(document).on('scroll', function () {
    value = $(window).scrollTop()
    progressBar.attr('value', value)
  })
}
