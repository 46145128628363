window.sv.moreMenu = function () {
    // elements
  const $menu = $('.more-sub-menu')
  const $more = $('#more')
  const $closeButtonInside = $('.close-sub-menu')
  const $body = $('body')

    // methods
  const openMenu = function () {
    $menu.show()

    $more.addClass('open')
    $more.find('i').addClass('up')
    $more.find('i').removeClass('down')

    if ($body.width() <= 768) {
      $body.css('position', 'fixed')
    }
  }
  const closeMenu = function () {
    $menu.hide()

    $more.removeClass('open')
    $more.find('i').addClass('down')
    $more.find('i').removeClass('up')

    if ($body.width() <= 768) {
      $body.css('position', 'relative')
    }
  }

  const toggleMenu = function () {
    if ($menu.is(':visible')) {
      closeMenu()
    } else {
      openMenu()
    }
  }

    // listeners
  $more.click(toggleMenu)
  $closeButtonInside.click(closeMenu)
  $(document).mouseup(function (e) {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0 && !($more.is(e.target) || $more.has(e.target).length !== 0)) {
      closeMenu()
    }
  })
}
