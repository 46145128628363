const initAll = () => {
  jQuery('div.wpcf7 > form').each(function () {
    var $form = $(this)
    if (!$form.hasClass('initialized')) {
      wpcf7.initForm($form)
      $form.addClass('initialized')
      if (wpcf7.cached) {
        wpcf7.refill($form)
      }
    }
  })
	/*
  jQuery('div.wpcf7 > form').wpcf7InitForm()
  jQuery('form.wpcf7-form')
		.each(function () {
  jQuery(this).find('img.ajax-loader').last().remove()
	})
  */
}

const pageSetup = () => {
  $('div.wpcf7 > form').each(function () {
    var $form = $(this)
    $form.addClass('initialized')
  })
}

export default {
  initAll, pageSetup
}
