window.sv.SearchHandler = function () {
    // Show the search menu
  $('.search-icon , .mobile-search-close, .search-close').click(function (e) {
    e.preventDefault()

    var menuEl = $('#menu-search-area')

    if (menuEl.is(':visible')) {
      $('#menu-search-area .search-form-container').fadeToggle(200, 'swing', function () {
        $('#menu-search-area').slideToggle()
      })
      $('body').css('overflow', 'auto')
    } else {
      menuEl.slideToggle(200, 'swing', function () {
        $('#menu-search-area .search-form-container').fadeToggle()
        menuEl.find('[name="s"]').focus()
      })
      $('body').css('overflow', 'hidden')
    }

    // console.log('cena 2')
    $('.search-form-input').focus()
  })

    // Add click outside element to close
    // Stop propagatsion
  $('.search-form-container').bind('click', function (ev) {
    ev.stopPropagation()
  })

  $('#menu-search-area').bind('click', function (e) {
    e.preventDefault()

    if (menuEl.is(':visible')) {
      $('#menu-search-area .search-form-container').fadeToggle(200, 'swing', function () {
        $('#menu-search-area').slideToggle()
      })
      $('body').css('overflow', 'auto')
    } else {
      $('#menu-search-area').slideToggle(200, 'swing', function () {
        $('#menu-search-area .search-form-container').fadeToggle()
      })
      $('body').css('overflow', 'hidden')
    }

    // console.log('cenas')
    $('.search-form-input').focus()
  })

    // Delete the search input
  $('.js-delete-search').click(function (e) {
    $('#s2').val('')
  })

    // Handle the text search input and enable/disable the search button
  $('#s').keyup(function (e) {
    if (e.keyCode == 27) { // escape key maps to keycode `27`
      $('#menu-search-area').removeClass('active')
    } else {
      var $textCharCount = $(this).val().length

      if ($textCharCount > 0) {
        $('#searchsubmit').removeAttr('disabled')
      } else {
        $('#searchsubmit').attr('disabled', 'disabled')
      }
    }
  })
}
