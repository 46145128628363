/**
 * Control the header
 */
const threshold = 1000
window.sv.ajaxViewController = function () {
  var button
  $('[data-scroll-listen]').each(function () {
    button = $(this)
    if (button.data('scroll-listen')) {
      configureButtonToListen(button)
    }
  })
}

const configureButtonToListen = (button) => {
  let bTop = button.offset().top
  const doc = $(document), win = $(window)
  const listenUpdate = () => {
    listenScroll()
    setTimeout(() => {
      button.attr('data-requesting', 'false')
      button.show()
      bTop = button.offset().top
      // console.log(bTop, button.attr('data-show-more') === 'true')
      if (button.attr('data-show-more') === 'true') {
        if (Number(button.attr('data-page')) > Number(button.attr('data-scroll-pages'))) {
          // console.log('remove Listener 1')
          button.off('data-updated', listenUpdate)
          win.off('scroll', listenScroll)
        } else {
          button.hide()
        }
      } else {
        // console.log('remove Listener 2')
        button.off('data-updated', listenUpdate)
        win.off('scroll', listenScroll)
        button.hide()
      }
    }, 50)
  }
  const listenScroll = () => {
    if (doc.scrollTop() + win.height() + threshold > bTop && button.attr('data-requesting') === 'false') {
      button.attr('data-requesting', 'true')
      button.trigger('click')
    }
  }
  // console.log('listen', button)
  button.attr('data-requesting', 'false')
  button.hide()
  button.on('data-updated', listenUpdate)
  win.on('scroll', listenScroll).trigger('scroll')
  button.on('data-updated', () => {
    window.sv.ellipsis()
  })
}
