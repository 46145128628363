window.sv.openMagLocationNav = function () {
  $('[data-om-location-vis]').hide()
  var el = $('[data-om-location]')
  el.on('click', (e) => {
    e.preventDefault()
    if ($(e.currentTarget).data('om-disabled') !== true) {
      el.removeClass('tab-active')
      el.addClass('tab-inactive')
      $('[data-om-location-vis]').hide()
      $("[data-om-location-vis='" + $(e.currentTarget).data('om-location') + "']").fadeIn(200)
      $(e.currentTarget).addClass('tab-active').removeClass('tab-inactive')

      if (window.map) {
        window.map.setCenter(window[$(e.currentTarget).data('om-location-map-latlng')])
      }
    }
  })
  el.eq(0).trigger('click')
}
