(function ($) {
  const setupGalleries = function () {
    var galleryContainers = $('.article-gallery')
    galleryContainers.on('afterChange', function (evt, slick, currentSlide) {
      var currentSlideEl = slick.$slides.get(currentSlide)
      if (currentSlideEl.classList.contains('google-ad')) {
        window.googleAdFill(currentSlideEl.querySelector('.ad'))
      }
    })
    galleryContainers.slick({
      infinite: false
    })
  }
  setupGalleries()
}(jQuery))
