window.sv.SocialSharing = function () {
  $('.fb-social , .pinterest-social').click(function (e) {
    e.preventDefault()
    window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')
    return false
  })
}

window.sv.MobileSocialShare = function () {
  var showSocial = function () {
    var that = $(this)
    that.off('click', showSocial)
    if ($(this).parent().siblings('.share-icons').length) {
      if (that.attr('data-animating') === '0') {
        that.fadeOut('slow')
        that.attr('data-animating', '1')
        $(this).parent().siblings('.share-icons').css('visibility', 'visible').css('opacity', 1).hide().fadeIn(400, 'swing', () => {
          that.attr('data-animating', '0')
        })
      }
    } else {
      if (that.attr('data-animating') === '0') {
        that.fadeOut('slow')
        that.attr('data-animating', '1')
        $(this).siblings('.share-icons').css('visibility', 'visible').css('opacity', 1).hide().fadeIn(400, 'swing', () => {
          that.attr('data-animating', '0')
        })
      }
    }
    var hideSocial = function () {
      that.fadeIn()
      if (that.parent().siblings('.share-icons').length) {
        // console.log(that.parent().siblings('.share-icons'))
        that.parent().siblings('.share-icons').animate({'opacity': 0}, {
          complete: () => {
            that.parent().siblings('.share-icons').hide()
          }
        })
      } else {
        that.siblings('.share-icons').animate({'opacity': 0}, {
          complete: () => {
            that.siblings('.share-icons').hide()
          }
        })
      }
      $('body').unbind('click', hideSocial)
      that.on('click', showSocial)
    }
    setTimeout(function () {
      $('body').bind('click', hideSocial)
    }, 500)
  }

  var mobileSocial = $('.mobile-social')

  mobileSocial.attr('data-animating', '0')
  mobileSocial.off('click', showSocial).on('click', showSocial)
}
