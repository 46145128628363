(function ($) {
  $.fn.numberCounter = function () {
    const setupElement = (elem) => {
      $(window).on('scroll', function () {
        if (elem.offset().top < $(document).scrollTop() + $(window).height() && elem.data('counted') !== '1') {
          elem.data('counted', '1')
          const steps = 14, delay = Math.random() * 500, speed = 50 + Math.round(Math.random() * 20)
          let targetNum = parseInt(elem.html().replace(/ /i, ''))
          startCountingNumber(elem, 0, targetNum, Math.round(targetNum / steps), delay, speed, elem.data('count-number'))
        }
      }).trigger('scroll')
    }
    const startCountingNumber = (target, start, end, step, delay, speed, nFormat) => {
      let interval
      start -= step
      const loop = () => {
        start += step
        target.html(String(start))
        if (start > end) {
          start = end
          target.html(String(start))
          clearInterval(interval)
        }
      }
      setTimeout(() => {
        loop()
        interval = setInterval(loop, speed)
      }, delay)
    }
    return this.each(function () {
      setupElement($(this))
    })
  }
}(jQuery))
