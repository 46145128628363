import cf7 from '../functions/_contact-form-7'

jQuery('#sv03-load-more').click(function (e) {
  var $clickedElement = $(this)
  var $loaderElement = $clickedElement.siblings('.loader')

  $($clickedElement).hide()
  $($loaderElement).toggleClass('active')

  var $currentPage = $(this).attr('data-page')
  var $categoryId = $(this).attr('data-category')
  var $type = $(this).attr('data-type')

  var $gavirtualpath = $(this).attr('data-ga-virtual-path')
  var $gavirtualtitle = $(this).attr('data-ga-virtual-title')
  var extra = $(this).attr('data-extra') ? JSON.parse($(this).attr('data-extra')) : {}
  var data = {
    action: 'sv03_load_more_handler',
    nonce: sv03_load_more_handler.nonce,
    currentPage: $currentPage,
    categoryId: $categoryId,
    type: $type
  }
  for (var v in extra) {
    data[v] = extra[v]
  }

  // console.log (sv03_load_more_handler.url);

  // Execute the ajax request
  $.post(sv03_load_more_handler.url, data, function (response) {
    // console.log( response.data );
    if (response.success) {
      // console.log('Google Analytics', $gavirtualtitle, $gavirtualpath, $currentPage, response.data.data.posts.length)

      if (!!$gavirtualtitle && !!$gavirtualpath && $gavirtualpath !== '' && response.data.data.posts.length > 0) {
        var page = $gavirtualpath + '/load-' + $currentPage + '/',
          title = $gavirtualtitle + ' - Página: ' + $currentPage

        // console.log('dataLayer push')
        dataLayer.push({
          'event': 'virtualPageview',
          'virtualPageURL': page,
          'virtualPageTitle': title
        })

        // console.log('GA Called', dataLayer, page, title)
      }

      $clickedElement.attr('data-page', response.data.data.current_page)
      $clickedElement.attr('data-show-more', !!response.data.data.show_more)
      if (response.data.data.show_more) {
        $clickedElement.show()
      } else {
        $clickedElement.closest('.container').hide()
      }
      if (!!response.data.data.posts && response.data.data.posts.length > 0) {
        $('.js-articles-container').append(response.data.output)
      }
      $($loaderElement).toggleClass('active')

      $clickedElement.trigger('data-updated')
      window.sv.MobileSocialShare()
      window.sv.SocialSharing()
      if (window.GSLoader) {
        window.GSLoader.reset()
      }
      cf7.initAll()
    }
  })
})
