/**
 * Control the header
 */
const threshold = 1000
window.sv.openMagAjaxViewController = function () {
  let loadButton = $('#openmag-articles')
  configureLoadMore(loadButton)
  configureDateFilters(loadButton)
  var button
  $('[data-openmag-scroll-listen]').each(function () {
    button = $(this)
    if (button.data('openmag-scroll-listen')) {
      configureButtonToListen(button)
    }
  })
}

const configureButtonToListen = (button) => {
  let bTop = button.offset().top
  const doc = $(document), win = $(window)
  const listenUpdate = () => {
    listenScroll()
    setTimeout(() => {
      button.attr('data-requesting', 'false')
      button.show()
      bTop = button.offset().top
      // console.log(bTop, button.attr('data-show-more') === 'true')
      if (button.attr('data-show-more') === 'true') {
        if (Number(button.attr('data-page')) > Number(button.attr('data-scroll-pages'))) {
          button.off('data-updated', listenUpdate)
          win.off('scroll', listenScroll)
        } else {
          button.hide()
        }
      } else {
        button.off('data-updated', listenUpdate)
        win.off('scroll', listenScroll)
        button.hide()
      }
    }, 50)
  }
  const listenScroll = () => {
    if (doc.scrollTop() + win.height() + threshold > bTop && button.attr('data-requesting') === 'false') {
      button.attr('data-requesting', 'true')
      button.trigger('click')
    }
  }
  button.attr('data-requesting', 'false')
  button.hide()
  button.on('data-updated', listenUpdate)
  win.on('scroll', listenScroll).trigger('scroll')
  button.on('data-updated', () => {
    window.sv.ellipsis()
  })
}

const configureDateFilters = (button) => {
  if (button.data('date-filters') !== '') {
    const buttons = $(button.data('date-filters'))
    buttons.each(function () {
      $(this).click(function (e) {
        e.preventDefault()
        if (button.attr('data-date') !== $(this).data('ajax-openmag-date-filter')) {
          buttons.removeClass('active')
          $(this).addClass('active')
          button.attr('data-next-offset', 0)
          button.attr('data-date', $(this).data('ajax-openmag-date-filter'))
          const contentTarget = $(button.attr('data-target'))
          contentTarget.slideUp(400, () => {
            contentTarget.html('')
            // button.trigger('click')
            configureButtonToListen(button)
          })
        }
      })
    })
  }
}

const configureLoadMore = (button) => {
  let page_num = 0
  button.click(function (e) {
    var $clickedElement = $(this)
    var $loaderElement = $clickedElement.siblings('.loader')

    $($clickedElement).hide()
    $($loaderElement).toggleClass('active')
    var $target = $(this).attr('data-target')
    var $nextOffset = $(this).attr('data-next-offset')
    var $type = $(this).attr('data-type')
    var $date = $(this).attr('data-date')
    var $gavirtualpath = $(this).attr('data-ga-virtual-path')
    var $gavirtualtitle = $(this).attr('data-ga-virtual-title')
    var extra = $(this).attr('data-extra') ? JSON.parse($(this).attr('data-extra')) : {}
    var data = {
      action: 'open_mag_articles_handler',
      nonce: open_mag_articles_handler.nonce,
      nextOffset: $nextOffset,
      type: $type,
      date: $date
    }
    for (var v in extra) {
      data[v] = extra[v]
    }

    // console.log (sv03_load_more_handler.url);

    // Execute the ajax request
    $.post(open_mag_articles_handler.url, data, function (response) {
      // console.log( response.data );
      if (response.success) {
        // console.log('Google Analytics', $gavirtualtitle, $gavirtualpath, page_num, response.data.data.posts.length)

        if (!!$gavirtualtitle && !!$gavirtualpath && $gavirtualpath !== '' && response.data.data.posts.length > 0) {
          var page = $gavirtualpath + '/load-' + page_num + '/',
            title = $gavirtualtitle + ' - Página: ' + page_num

          dataLayer.push({
            'event': 'virtualPageview',
            'virtualPageURL': page,
            'virtualPageTitle': title
          })

          // console.log('GA Called', dataLayer, page, title)
        }

        $clickedElement.attr('data-next-offset', response.data.data.next_offset)
        $clickedElement.attr('data-show-more', !!response.data.data.show_more)
        if (response.data.data.show_more) {
          $clickedElement.show()
        } else {
          $clickedElement.closest('.btn-subscribe__wrapper').hide()
        }
        if (!!response.data.data.posts && response.data.data.posts.length > 0) {
          $($target).append(response.data.output)
          $($target).slideDown()
        }
        $($loaderElement).toggleClass('active')
        $clickedElement.trigger('data-updated')
        page_num++
      }
    })
  })
}
