/*
    Function to load quartz articles when the user is around 80% read
 */

window.sv.QuartzArticleHander = function () {
    // Declare the variables
  var winHeight = $(window).height(),
        // Main is our container
    mainHeight = $('main').height(),
        // Where we are looking at
    currentScrollLocation,
        // When do we start the request
    HeightBeforeRequest = mainHeight - 1500,
        // Controller to check if we're "ajaxing"
    requestingAjax = false

  $(document).on('scroll', function () {
        // Get the current height, if might have changed!
    winHeight = $(window).height()
        // Get the scroll location
    currentScrollLocation = $(window).scrollTop()

    // console.log('Current height is ' + currentScrollLocation + ' Nedeed is ' + HeightBeforeRequest)

        // If we are on the request area... and not requesting
    if (currentScrollLocation >= HeightBeforeRequest && !requestingAjax) {
      requestingAjax = true

      var articleOnView = GetLatestArticleOnPage()
      // console.log('I returned with ' + articleOnView)
                // debug only
                // var articleOnView = true;
      if (articleOnView) {
                   // console.log( "Found article " + articleOnView );
        var data = {
          action: 'sv_articles_load_more_handler',
          nonce: sv_articles_load_more_handler.nonce,
          latest_article: articleOnView
                        // currentPage : $currentPage
        }

                    // Execute the ajax request
        $.post(sv_articles_load_more_handler.url, data, function (response) {
          if (response.success) {
                            /* $clickedElement.attr("data-page" , response.data.data.current_page );
                            if( ! response.data.data.show_more ){
                                $clickedElement.hide();
                            }else{
                                $clickedElement.show();
                            }

                            $(".loader").toggleClass("active");
                            $(".sv06-js-articles-container").append( response.data.output ); */

            var mainEl = $('main')

			  mainEl.append(response.data.output)
            mainHeight = mainEl.height()
            HeightBeforeRequest = mainHeight - 1500
          }

          requestingAjax = false
        })
      } else {
        // console.log('Is not set')
      }
    } else {
           // console.log( "Not yet...." );
    }
  })

  function GetLatestArticleOnPage () {
        // get all articles
    var articleElements = $('article')
    var foundArticleID = null

    articleElements.each(function (item) {
      if ($(this).isOnScreen()) {
        // console.log('I found ' + $(this).data('id'))
        foundArticleID = $(this).data('id')

                // return $(this).data('id');
      }
    })

    return foundArticleID
  }
}
