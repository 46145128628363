(function ($) {
  $.fn.checkboxStyledParentLabel = function (options) {
    const settings = $.extend({}, options)

    let element, label
    return this.each((index, item) => {
      $(item).on('change', function () {
        element = $(this)
        label = element.closest('label')
        // console.log(element, label)
        label.removeClass('active')
        if (element.is(':checked')) {
          label.addClass('active')
        }
      })
    })
  }
}(jQuery))
