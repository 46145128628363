/**
 * Control the header
 */
window.sv.classifyPWithImg = function () {
  $('p > img').each(function () {
    const classes = $(this).attr('class').split(' ')
    if (classes) {
      $(this).parent().addClass('has_image')
      for (let i = 0; i < classes.length; i++) {
        $(this).parent().addClass('has_' + classes[i])
      }
    }
  })
}
