window.sv.ellipsis = function () {
  let ellipsisElement,
    lines, maxHeight, contentNode, contentNodeWords, numWords

  const run = () => {
    $('.ellipsis').each(function () {
      ellipsisElement = $(this)

      contentNode = ellipsisElement.find('a')
      if (!contentNode.length) {
        contentNode = ellipsisElement
      }

      if (!ellipsisElement.data('original')) {
        ellipsisElement.data('original', contentNode.html())
      }

      contentNode.html(ellipsisElement.data('original'))

      lines = (ellipsisElement.data('ellipsis-lines')) ? parseInt(ellipsisElement.data('ellipsis-lines')) : 3

      maxHeight = Math.max(parseInt(contentNode.css('min-height')), parseInt(contentNode.css('line-height')) * lines)

      if (contentNode.css('display') === 'inline') {
        contentNode.css('display', 'inline-block')
      }

      if (contentNode.height() > maxHeight) {
        contentNodeWords = contentNode.html().split(' ')
        numWords = contentNodeWords.length

        while (numWords > 0) {
          contentNode.html(contentNodeWords.slice(0, numWords).join(' ') + '…')
          numWords--
          if (contentNode.height() <= maxHeight) {
            break
          }
        }
      }
    })
  }
  $(window).on('resize', run)
  run()
}
