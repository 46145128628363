$(function () {
  var data = {
    action: 'admin_bar_handler',
    nonce: admin_bar_handler.nonce
  }

  $.post(admin_bar_handler.url, data, function (response) {
    // console.log('RESPONSE ADMIN BAR', response)
  })
})
