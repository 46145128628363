
jQuery('#sv-recipes-group-load-more').click(function (e) {
  var $clickedElement = $(this)
  var $loaderElement = $clickedElement.siblings('.loader')

  $($clickedElement).hide()
  $($loaderElement).toggleClass('active')

  var $currentPage = $(this).attr('data-page')
  var data = {
    action: 'sv_grupo_receitas_load_more_handler',
    nonce: sv_grupo_receitas_load_more_handler.nonce,
    currentPage: $currentPage
  }

    // Execute the ajax request
  $.post(sv_grupo_receitas_load_more_handler.url, data, function (response) {
    if (response.success) {
      $clickedElement.attr('data-page', response.data.data.current_page)
      if (!response.data.data.show_more) {
        $clickedElement.hide()
      } else {
        $clickedElement.show()
      }

      $($loaderElement).toggleClass('active')
      $('.sv-grupo-receitas-js-articles-container').append(response.data.output)
    }
  })
})
