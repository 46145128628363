import cf7 from './functions/_contact-form-7'
// Funções que são chamadas ao abrir a página
$(function () {
  sv.headerHandler()
  sv.MenuItemHandler()
  sv.HandleProgressBar()
  sv.PartnersSlick()
  sv.RelatedToBottom()
  sv.SocialSharing()
  sv.SearchHandler()
  sv.FooterScrollTopHandler()
  sv.MobileSocialShare()
  sv.sideMenu()
  sv.classifyPWithImg()
  sv.newsletterResponse()
          // sv.QuartzArticleHander();
  sv.ajaxViewController()
  sv.openMagAjaxViewController()
  sv.ellipsis()
  sv.openMagMap()
  sv.openMagScroll()
  sv.openMagLocationNav()
  sv.openMagNumberCounters()
  sv.avoidHashLinksToJumpTop()
  sv.coverImages()
  sv.moreMenu()
  sv.collapsers()
  sv.checkboxStyledParentLabel()
  cf7.pageSetup()
  sv.mortgageSimulator()
})
