import cf7 from '../functions/_contact-form-7'

jQuery('#sv06-load-more').click(function (e) {
  var $clickedElement = $(this)
  var $currentPage = $(this).attr('data-page')

  var $gavirtualpath = $(this).attr('data-ga-virtual-path')
  var $gavirtualtitle = $(this).attr('data-ga-virtual-title')
  var extra = $(this).attr('data-extra') ? JSON.parse($(this).attr('data-extra')) : {}

  $('.loader').toggleClass('active')
  $clickedElement.hide()
  var data = {
    action: 'sv06_load_more_handler',
    nonce: sv06_load_more_handler.nonce,
    currentPage: $currentPage
  }
  for (var v in extra) {
    data[v] = extra[v]
  }

    // Execute the ajax request
  $.post(sv06_load_more_handler.url, data, function (response) {
    if (response.success) {
      if (!!$gavirtualtitle && !!$gavirtualpath && $gavirtualpath !== '' && response.data.data.posts.length > 0) {
        var page = $gavirtualpath + '/load-' + $currentPage + '/',
          title = $gavirtualtitle + ' - Página: ' + $currentPage
                // console.log(page, title)

        dataLayer.push({
          'event': 'virtualPageview',
          'virtualPageURL': page,
          'virtualPageTitle': title
        })
      }

      $clickedElement.attr('data-page', response.data.data.current_page)
      $clickedElement.attr('data-show-more', !!response.data.data.show_more)
      if (response.data.data.show_more) {
        $clickedElement.show()
      } else {
        $clickedElement.closest('.container').hide()
      }

      $('.loader').toggleClass('active')
      if (!!response.data.data.posts && response.data.data.posts.length > 0) { $('.sv06-js-articles-container').append(response.data.output) }
      $clickedElement.trigger('data-updated')
      window.sv.MobileSocialShare()
      window.sv.SocialSharing()
      if (window.GSLoader) {
        window.GSLoader.reset()
      }
      cf7.initAll()
    }
  })
})
