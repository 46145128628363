window.sv.mortgageSimulator = function () {
  if ($('.sv-mortgage-simulator').length > 0) {
    $('.sv-mortgage-simulator').on('change', function () {
      document.getElementById('idade').value >= 18 && document.getElementById('idade').value < 70 && !isNaN(document.getElementById('idade').value) || (document.getElementById('idade').value = 30)

      document.getElementById('rendimento').value >= 100 && document.getElementById('rendimento').value <= 1E6 && !isNaN(document.getElementById('rendimento').value) || (document.getElementById('rendimento').value = 1000)

      document.getElementById('presta').value >= 0 && !isNaN(document.getElementById('presta').value) || (document.getElementById('presta').value = 0)

      if (document.getElementById('rendimento').value * 1 <= document.getElementById('presta').value * 1) { document.getElementById('presta').value = 0 }

      var taeg = 2.11 / 100 / 12 //  https://www.bportugal.pt/Mobile/BPStat/Pesquisa.aspx?flt=taeg%20habita%C3%A7%C3%A3o

      var pmax = 70 // idade máxima na maturidade

      var prazo

      prazo = document.getElementById('idade').value < 30 ? 40 : pmax - document.getElementById('idade').value

      var taxaesforco = 0.4

      var emprestimo = Math.round((taxaesforco * document.getElementById('rendimento').value - document.getElementById('presta').value) * (1 - Math.pow(1 + taeg, 12 * -prazo)) / taeg)

      var prestacao = Math.round(emprestimo / ((1 - Math.pow(1 + taeg, 12 * -prazo)) / taeg))

      document.getElementById('emprestimomaximo').innerHTML = emprestimo

      document.getElementById('prestacaomaxima').innerHTML = prestacao

      document.getElementById('prazomaximo').innerHTML = prazo
    })
  }

  const updateValues = function () {

  }
}
