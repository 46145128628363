window.sv.openMagScroll = function () {
  $(window).on('scroll', function () {
    var scrollPos = $(window).scrollTop()

    var el = $('.tickets-bar')

    if (scrollPos <= 0) {
      if (el.is(':visible')) {
        $('.tickets-bar').css('display', 'none')
      }
    } else {
      if (!el.is(':visible')) {
        $('.tickets-bar').css('display', 'flex')
      }
    }
  })
}
