import cf7 from './../functions/_contact-form-7'
import google from './../../ads/google'

jQuery('#sv08-load-more').click(function (e) {
  var $clickedElement = $(this)
  var $loaderElement = $clickedElement.siblings('.loader')

  $($clickedElement).hide()
  $($loaderElement).toggleClass('active')
  var $currentPage = $(this).attr('data-page')
  var $gavirtualpath = $(this).attr('data-ga-virtual-path')
  var $gavirtualtitle = $(this).attr('data-ga-virtual-title')
  var extra = $(this).attr('data-extra') ? JSON.parse($(this).attr('data-extra')) : {}

  var extraParams = ''
  for (var v in extra) {
    extraParams += '&' + v + '=' + extra[v]
  }

  $.get(sv08_load_more_handler.url + '?action=sv08_load_more_handler&currentPage=' + $currentPage + extraParams, function (response) {
        // console.log( response.data );
    if (response.success) {
      if (!!$gavirtualtitle && !!$gavirtualpath && $gavirtualpath !== '' && response.data.data.posts.length > 0) {
        var page = $gavirtualpath + '/load-' + $currentPage + '/',
          title = $gavirtualtitle + ' - Página: ' + $currentPage
                // console.log(page, title)

        dataLayer.push({
          'event': 'virtualPageview',
          'virtualPageURL': page,
          'virtualPageTitle': title
        })
      }
      $clickedElement.attr('data-page', response.data.data.current_page)
      $clickedElement.attr('data-show-more', !!response.data.data.show_more)
      if (response.data.data.show_more) {
        $clickedElement.show()
      } else {
        $clickedElement.closest('.container').hide()
      }
      if (!!response.data.data.posts && response.data.data.posts.length > 0) { $('.sv-08-latest').append(response.data.output) }
      $($loaderElement).toggleClass('active')
      $clickedElement.trigger('data-updated')
      window.sv.MobileSocialShare()
      window.sv.SocialSharing()
      if (window.GSLoader) {
        window.GSLoader.reset()
      }
      cf7.initAll()
      google.setupElements()
    }
  }, 'json')
})
